import "./Membership.css";
import policies from "./PoliciesContent";
import membershipContent from "./MembershipContent";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import { RxComponent1 } from "react-icons/rx";
import { RiDoubleQuotesL } from "react-icons/ri";

export default function Membership({ curLanguage, onHandlePrompt }) {
  return (
    <section className="component section-membership" id="membership">
      <div className="membership-content">
        <h2 className="components-header-h2">
          {curLanguage === "en" ? "Membership" : "Membership"}
        </h2>
        <div className="member-grid">
          <div className="member-grid-div">
            <div className="member-intro-div">
              <RiDoubleQuotesL className="member-intro-div-icon" />
              <h3 className="member-intro-div-h3">
                {curLanguage === "en" ? "The Club" : "Conheça o Clube"}
              </h3>
              {membershipContent[`${curLanguage}`].content.map((item) => (
                <p key={item} className="member-intro-div-p">
                  {item}
                </p>
              ))}
            </div>

            <div className="member-grid-advantages">
              <h3 className="member-grid-advantages-h3">
                {" "}
                {curLanguage === "en" ? "Advantages" : "Vantagens"}
              </h3>
              <ul className="adv-test">
                {membershipContent[`${curLanguage}`].advantagesItems.map(
                  (item) => (
                    <li key={item}>
                      <div>
                        <RxComponent1 className="member-grid-div-icon" />
                      </div>
                      <span className="member-grid-div-span">{item}</span>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <h3 className="member-plans-h3-mob">
            {curLanguage === "en"
              ? "Choose Your Plan"
              : "Escolha Sua Assinatura"}
          </h3>

          <div className="member-plans-box">
            <MembershipPlansCard
              isBlack={true}
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={
                membershipContent[`${curLanguage}`].haircutAndBeardPlan
              }
            />
            <MembershipPlansCard
              isBlack={true}
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={membershipContent[`${curLanguage}`].haircutPlan}
            />

            <MembershipPlansCardNew
              isBlack={true}
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={
                membershipContent[`${curLanguage}`].blackBeardPlan
              }
            />
            <MembershipPlansCard
              isBlack={false}
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={membershipContent[`${curLanguage}`].beardPlan}
            />
            <MembershipPlansCard
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={
                membershipContent[`${curLanguage}`].haircutGoldPlan
              }
            />
            <MembershipPlansCard
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={
                membershipContent[`${curLanguage}`].haircutAndBeardGoldPlan
              }
            />

            <MembershipPlansCard
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={
                membershipContent[`${curLanguage}`].haircutOnePlan
              }
            />
            <MembershipPlansCardNew
              isBlack={true}
              curLanguage={curLanguage}
              onHandlePrompt={onHandlePrompt}
              planCardContent={membershipContent[`${curLanguage}`].diamondPlan}
            />
          </div>
        </div>
        {/* <div className="member-details">
          <p>
            {curLanguage === "en"
              ? ` **This estimated price  shows that even using the plan 3 to 4
            times a month (most average of our customers) you are already saving
            money.`
              : `**Este preço demonstra que, mesmo utilizando o plano de 3 a 4 vezes por mês (a média da maioria dos nossos clientes), você já está economizando dinheiro.`}
          </p>
        </div> */}
      </div>
    </section>
  );
}

function MembershipPlansCard({
  curLanguage,
  planCardContent,
  onHandlePrompt,
  isBlack,
}) {
  if (!planCardContent) return;

  return (
    <div
      className={
        isBlack === true ? "member-plans-box-div-black" : "member-plans-box-div"
      }
    >
      <div className="member-plan-title">
        <span>{planCardContent.subtitle}</span>
        <h4>{planCardContent.title}</h4>
        <div className="member-plans-box-price">${planCardContent.price}*</div>
      </div>

      <ul>
        {planCardContent.list.map((item) => (
          <li key={item.listItem}>
            {item.isIncluded ? (
              <IoCheckmarkSharp className="member-plan-icon" />
            ) : (
              <IoCloseSharp className="member-plan-icon" />
            )}
            <span
              className={isBlack === true ? "plan-focus-black" : "plan-focus"}
            >
              {item.listItem}
            </span>
          </li>
        ))}
        <li>
          <span>
            {curLanguage === "en" ? "*Monthly payment." : "*Pagamento Mensal."}
          </span>
        </li>
      </ul>
      <div className="subscribe-div">
        <button
          className="subscribe-button"
          onClick={() =>
            onHandlePrompt(
              policies.membership,
              "subscription",
              planCardContent.planLink
            )
          }
        >
          {curLanguage === "en" ? "Subscribe" : "Assinar"}
        </button>
      </div>
    </div>
  );
}

function MembershipPlansCardNew({
  curLanguage,
  planCardContent,
  onHandlePrompt,
  isBlack,
}) {
  if (!planCardContent) return;

  return (
    <div
      className={
        isBlack === true ? "member-plans-box-div-black" : "member-plans-box-div"
      }
    >
      <div className="member-plan-title">
        <span>{planCardContent.subtitle}</span>
        <h4>{planCardContent.title}</h4>
        <div className="member-plans-box-price">*</div>
      </div>

      <ul>
        {planCardContent.list.map((item) => (
          <li key={item.listItem}>
            {item.isIncluded ? (
              <IoCheckmarkSharp className="member-plan-icon" />
            ) : (
              <IoCloseSharp className="member-plan-icon" />
            )}
            <span
              className={isBlack === true ? "plan-focus-black" : "plan-focus"}
            >
              {item.listItem}
            </span>
          </li>
        ))}
        {/* <li>
          <span>
            {curLanguage === "en" ? "*Monthly payment." : "*Pagamento Mensal."}
          </span>
        </li> */}
      </ul>
      {/* <div className="subscribe-div">
        <button
          className="subscribe-button"
          onClick={() =>
            onHandlePrompt(
              policies.membership,
              "subscription",
              planCardContent.planLink
            )
          }
        >
          {curLanguage === "en" ? "Subscribe" : "Assinar"}
        </button>
      </div> */}
    </div>
  );
}
